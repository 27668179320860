import React, { useEffect, useState } from 'react'
import { AppImage } from '../../../types/AppImage';
import ImageActions from '../../../actions/image-actions';
import MainLayout from '../../../layouts/MainLayout';
import { Button, FileInput, Modal } from 'flowbite-react';

export default function ImageList() {
    const imageUploadRef = React.createRef<HTMLInputElement>();
    const [loading, setLoading] = useState(false);
    const [fileUploading, setFileUploading] = useState(false);
    const [file, setFile] = useState<File>();
    const [openModal, setOpenModal] = useState(false);
    const [imageId, setImageId] = useState<string>();

    const [images, setImages] = useState<AppImage[]>([]);

    useEffect(() => {
        setLoading(true);
        ImageActions.getList().then((data) => {
            if (data) {
                setImages(data)

            }
            setLoading(false);
        })
    }, [])

    function startUpload() {

        if (!file) {
            return;
        }

        setFileUploading(true);

        try {
            ImageActions.upload(file).then((data) => {
                setImages([...images, data]);

            });
        } catch (error) {
            console.log(error);

        } finally {
            setFileUploading(false);
        }

    }

    function handleDelete(id: string): void {
        ImageActions.delete(id).then(() => {
            ImageActions.getList().then((data) => {
                if (data) {
                    setImages(data)
                }
            })
        })
    }

    return (
        <MainLayout>
            <h1 className="text-2xl font-bold">Images</h1>
            <div className="mt-2">
                <img src={file ? URL.createObjectURL(file) : ""} alt="" />

                <div className="flex gap-4">
                    <FileInput id="image" accept="image/*" sizing="md" disabled={fileUploading} onChange={
                        (e) => {
                            setFile(e.target.files![0]);
                        }
                    } ref={imageUploadRef} />
                    {
                        fileUploading && <span className="ml-2 pt-2 text-gray-500">Uploading...</span>
                    }
                    <Button disabled={fileUploading || !file} onClick={
                        () => {
                            startUpload();
                        }
                    }>Upload</Button>

                    <Button disabled={fileUploading || !file} onClick={
                        () => {
                            // reset image
                            setFile(undefined);
                            imageUploadRef.current!.value = "";
                        }
                    }>Clear</Button>
                </div>
                <span className="text-sm text-gray-500">PNG, JPG, JPEG, WEBP...</span>
            </div>

            <div className="grid grid-cols-4 gap-4">

                {loading ? (<div>Loading...</div>) : (images.map((image) => {


                    return <div className="bg-gray-100 p-4" key={image.id}>
                        <img src={image.url} alt=""/>

                        <div className="mt-4">
                            <p>{image.name}</p>
                        </div>

                        <div className="mt-4">

                            <Button className="bg-red-500 text-white px-4 py-2 rounded" onClick={() => { setOpenModal(true); setImageId(image.id) }}>
                                Delete
                            </Button>
                        </div>
                    </div>
                }))}

            </div>

            <Modal show={openModal} onClose={() => setOpenModal(false)}>

                <Modal.Header>Delete Image</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Are you sure you want to delete this image?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        handleDelete(imageId!);
                        return setOpenModal(false);
                    }}>Yes</Button>
                    <Button color="gray" onClick={() => setOpenModal(false)}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </MainLayout >
    )
}
