import MainLayout from "./layouts/MainLayout";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useEffect, useState } from "react";
import Dashboard from "./pages/dashboard/Dashboard";

export default function App() {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    // automatically sign-in
    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    if (auth.isLoading) {
        return <div>Signing you in/out...</div>;
    }
    return (
        <MainLayout>
            <Dashboard />
        </MainLayout>
    )
}
