"use client";
import { Navbar, Dropdown, Avatar, Button } from "flowbite-react";
import { useAuth } from "react-oidc-context";
import { HiMenu } from "react-icons/hi";
import { useSidebarStore } from "../stores/sidebar";



export default function Header() {
    const auth = useAuth();
    const toggle = useSidebarStore((state) => state.toggle)
    return (
        <Navbar fluid rounded>
            <Button className="block lg:hidden"
                onClick={() => toggle()}
            >
                <HiMenu />
            </Button>
            <Navbar.Brand href="/">
                <img src="/logo192.png" className="mr-3 h-6 sm:h-9" alt="Logo" />
                <span className="font-semibold text-xl dark:text-white whitespace-nowrap self-center">SMJ</span>
            </Navbar.Brand>
            <div className="flex md:order-2">
                {
                    auth.isAuthenticated ? <Dropdown
                        arrowIcon={false}
                        inline
                        label={<Avatar alt="User settings" img="/logo192.png" rounded />}
                    >
                        <Dropdown.Header>
                            <span className="block text-sm">{auth.user?.profile.name}</span>
                            <span className="block font-medium text-sm truncate">{auth.user?.profile.email}</span>
                        </Dropdown.Header>
                        <Dropdown.Item href="#">Profile</Dropdown.Item>
                        <Dropdown.Item href="#">Settings</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item>
                            <Button className="w-full" onClick={() => auth.signoutSilent()}>Logout</Button>
                        </Dropdown.Item>
                    </Dropdown>

                        : <Button onClick={() => auth.signinRedirect()}>Login</Button>
                }
                <Navbar.Toggle />
            </div>
            {/* <Navbar.Collapse>
                <Navbar.Link href="#" active>
                    Home
                </Navbar.Link>
            </Navbar.Collapse> */}
        </Navbar>
    );
}
