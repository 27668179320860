
"use client";

import { Button, Checkbox, FileInput, Label, TextInput } from "flowbite-react";
import MainLayout from "../../layouts/MainLayout";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { slugify } from "../../utils/slugify";
import { Post } from "../../types/Post";
import { useForm } from "react-hook-form";
import { Editor } from "../../components/Editor";
import { AppImage } from "../../types/AppImage";
import React from "react";
import PostActions from "../../actions/post-actions";
import ImageActions from "../../actions/image-actions";

export default function ClientEdit() {
    const params = useParams();

    const [post, setPost] = useState<Post>();

    const [loading, setLoading] = useState(true);

    const [fileUploading, setFileUploading] = useState(false);

    const [file, setFile] = useState<AppImage>();

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<Post>({
    })

    function onSubmit(data: Post): void {
        setLoading(true);
        const updatedPost = {
            ...post,
            ...data
        }
        PostActions.update(post!.id!, updatedPost).then(() => {
            setLoading(false);
            navigate("/posts");
        });
    }

    useEffect(() => {
        if (params.slug) {
            setLoading(true);
            PostActions.getBySlug(params.slug).then((data) => {
                setPost(data!);
                getImage(data!.image);
                setValue("title", data!.title);
                setValue("slug", data!.slug);
                setValue("image", data!.image);
                setValue("summary", data!.summary);
                setValue("content", data!.content);
                setValue("tags", data!.tags);
                setValue("isPublished", data!.isPublished);

                setLoading(false);
            });
        }
    }, [params.slug, setValue]);


    function startUpload(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];

        if (!file) {
            return;
        }

        setFileUploading(true);

        ImageActions.upload(file).then((data) => {
            setFile(data);
            setValue("image", data.id);

            setFileUploading(false);
        });
    }


    function getImage(id?: string): void {
        if (id) {
            ImageActions.get(id).then((data) => {
                if (data) {
                    setFile(data);
                }
            })
        }

    }

    const imageUploadRef = React.useRef<HTMLInputElement>(null);

    return (
        <MainLayout>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="flex max-w-3xl flex-col gap-4 p-4">
                    <div>
                        <div className="mb-2 block w-full">
                            <Label htmlFor="title" value="Title" />
                        </div>
                        <TextInput id="title" sizing="md" placeholder="A Nice Title" {...register("title", { required: true })} onChange={(e) => setValue("slug", (slugify(e.target.value)))} />
                        {errors.title && <span className="text-red-500">This field is required</span>}

                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="slug" value="Slug" />
                        </div>
                        <TextInput id="slug" sizing="md" {...register("slug", { required: true })} />
                        {errors.slug && <span className="text-red-500">This field is required</span>}
                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="image" value="Image" />
                        </div>
                        <img className="mb-2" src={file?.url} alt="" />
                        <TextInput id="image" type="text" sizing="md"  {...register("image", { required: false })} />
                        <div className="mt-2">
                            <div className="flex gap-4">
                                <FileInput id="image" accept="image/*" sizing="md" disabled={fileUploading} onChange={
                                    (e) => {
                                        startUpload(e);
                                    }
                                } ref={imageUploadRef} />
                                {
                                    fileUploading && <span className="ml-2 pt-2 text-gray-500">Uploading...</span>
                                }
                                <Button onClick={
                                    () => {
                                        // reset image
                                        setFile(undefined);
                                        setValue("image", undefined);
                                        imageUploadRef.current!.value = "";
                                        // delete image
                                    }
                                }>Clear</Button>
                            </div>
                            <span className="text-sm text-gray-500">PNG, JPG, JPEG</span>
                        </div>
                    </div>

                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="summary" value="Summary" />
                        </div>
                        <TextInput id="summary" type="text" sizing="md" {...register("summary", { required: true })} />
                        {errors.summary && <span className="text-red-500">This field is required</span>}

                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="content" value="Content" />
                        </div>
                        <Editor {...register("content", { required: true })} contentChanged={(value) => setValue("content", value)} value={post?.content} />
                        {errors.content && <span className="text-red-500">This field is required</span>}

                    </div>
                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="tags" value="Tags" />
                        </div>
                        <TextInput id="tags" type="text" sizing="md"  {...register("tags")} />
                    </div>

                    <div>
                        <div className="mb-2 block">
                            <Label htmlFor="published" value="Published" />
                        </div>
                        <Checkbox
                            id="published"

                            {...register("isPublished")}
                        />
                    </div>

                    <Button type="submit" disabled={loading}>Submit</Button>

                </div>
            </form>
        </MainLayout>
    );
}
