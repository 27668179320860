import { create } from 'zustand'

interface SideBarState {
    isOpen: boolean
}

interface SideBarActions {
    toggle: () => void
}

export const useSidebarStore = create<SideBarState & SideBarActions>((set) => ({
    isOpen: false,
    toggle: () => set((state) => {
        return ({ isOpen: !state.isOpen })
    }),
}))