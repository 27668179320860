import React, { useEffect, useState } from 'react'
import MainLayout from '../../layouts/MainLayout'
import { Button, Modal, Table } from 'flowbite-react'
import { BiEdit, BiTrash } from "react-icons/bi";

import { PagedResult } from '../../types/PagedResult'
import { Post } from '../../types/Post'
import moment from 'moment';
import PostActions from '../../actions/post-actions';

export default function PostList() {
    const [openModal, setOpenModal] = useState(false);
    const [postId, setPostId] = useState<string>();

    const [postPage, setPostPage] = useState<PagedResult<Post>>()
    useEffect(() => {
        PostActions.getPage().then((data) => {
            setPostPage(data)
        })
    }, [])

    function handleDelete(id: string) {
        PostActions.delete(id).then(() => {
            PostActions.getPage().then((data) => {
                setPostPage(data)
            })
        })
    }


    return (
        <MainLayout>
            <div className="flex items-center justify-between mb-4 p-4">
                <h1 className="text-2xl font-bold">Posts</h1>
                <Button href="/posts/create" className='mr-4'>Create Post</Button>
            </div>
            <Table>
                <Table.Head>
                    <Table.HeadCell>
                        #
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Title
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Summary
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Tags
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Published
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Created At
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Updated At
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Actions
                    </Table.HeadCell>
                </Table.Head>
                {postPage?.items.map((post, index) => (
                    <Table.Body key={post.id}>
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                {index + 1}
                            </Table.Cell>
                            <Table.Cell>
                                <div className="flex items-center">
                                    <p className="font-medium text-gray-900 dark:text-white">{post.title}</p>
                                </div>
                                <div className="font-normal text-gray-700 dark:text-gray-400">{post.slug}</div>
                            </Table.Cell>
                            <Table.Cell>
                                {post.summary}
                            </Table.Cell>
                            <Table.Cell>
                                {post.tags?.split(',').join(', ')}
                            </Table.Cell>
                            <Table.Cell>
                                {post.isPublished ? 'Yes' : 'No'}
                            </Table.Cell>
                            <Table.Cell>
                                {(moment(post.createdAt)).format('DD MMM YYYY hh:mm A')}
                            </Table.Cell>
                            <Table.Cell>
                                {post.updatedAt ? (moment(post.updatedAt)).format('DD MMM YYYY hh:mm A') :
                                    '-'}
                            </Table.Cell>
                            <Table.Cell>
                                <div className="flex items-center">
                                    <a href={`/posts/edit/${post.slug}`} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                        <BiEdit className='mr-2' />
                                    </a>
                                    <BiTrash color='red' onClick={() => {
                                        setPostId(post.id);
                                        setOpenModal(true);
                                    }} />
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                ))}
            </Table>
            <Modal show={openModal} onClose={() => setOpenModal(false)}>

                <Modal.Header>Delete Post</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Are you sure you want to delete this post?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        handleDelete(postId!);
                        return setOpenModal(false);
                    }}>Yes</Button>
                    <Button color="gray" onClick={() => setOpenModal(false)}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </MainLayout>
    )
}
