import React, { useEffect, useState } from 'react'
import MainLayout from '../../layouts/MainLayout'
import { Button, Modal, Table } from 'flowbite-react'
import { BiEdit, BiTrash } from "react-icons/bi";

import { PagedResult } from '../../types/PagedResult'
import moment from 'moment';
import ProjectActions from '../../actions/project-actions';
import { Project } from '../../types/Project';

export default function ProjectList() {
    const [openModal, setOpenModal] = useState(false);
    const [projectId, setProjectId] = useState<string>();

    const [projectPage, setProjectPage] = useState<PagedResult<Project>>()
    useEffect(() => {
        ProjectActions.getPage().then((data) => {
            setProjectPage(data)
        })
    }, [])

    function handleDelete(id: string) {
        ProjectActions.delete(id).then(() => {
            ProjectActions.getPage().then((data) => {
                setProjectPage(data)
            })
        })
    }


    return (
        <MainLayout>
            <div className="flex items-center justify-between mb-4 p-4">
                <h1 className="text-2xl font-bold">Projects</h1>
                <Button href="/Projects/create" className='mr-4'>Create Project</Button>
            </div>
            <Table>
                <Table.Head>
                    <Table.HeadCell>
                        #
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Title
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Summary
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Tags
                    </Table.HeadCell>
                    <Table.HeadCell>
                        URL
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Created At
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Updated At
                    </Table.HeadCell>
                    <Table.HeadCell>
                        Actions
                    </Table.HeadCell>
                </Table.Head>
                {projectPage?.items.map((item, index) => (
                    <Table.Body key={item.id}>
                        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                {index + 1}
                            </Table.Cell>
                            <Table.Cell>
                                <div className="flex items-center">
                                    <p className="font-medium text-gray-900 dark:text-white">{item.name}</p>
                                </div>
                                <div className="font-normal text-gray-700 dark:text-gray-400">{item.slug}</div>
                            </Table.Cell>
                            <Table.Cell>
                                {item.summary}
                            </Table.Cell>
                            <Table.Cell>
                                {item.tags?.split(',').join(', ')}
                            </Table.Cell>
                            <Table.Cell>
                                {item.url}
                            </Table.Cell>
                            <Table.Cell>
                                {(moment(item.startDate)).format('DD MMM YYYY hh:mm A')}
                            </Table.Cell>
                            <Table.Cell>
                                {item.endDate ? (moment(item.endDate)).format('DD MMM YYYY hh:mm A') :
                                    '-'}
                            </Table.Cell>
                            <Table.Cell>
                                <div className="flex items-center">
                                    <a href={`/Projects/edit/${item.slug}`} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                        <BiEdit className='mr-2' />
                                    </a>
                                    <BiTrash color='red' onClick={() => {
                                        setProjectId(item.id);
                                        setOpenModal(true);
                                    }} />
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                ))}
            </Table>
            <Modal show={openModal} onClose={() => setOpenModal(false)}>

                <Modal.Header>Delete Project</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Are you sure you want to delete this Project?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        handleDelete(projectId!);
                        return setOpenModal(false);
                    }}>Yes</Button>
                    <Button color="gray" onClick={() => setOpenModal(false)}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </MainLayout>
    )
}
