
"use client";

import { Button, FileInput, Label, TextInput } from "flowbite-react";
import MainLayout from "../../layouts/MainLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Project } from "../../types/Project";
import { slugify } from "../../utils/slugify";
import { Editor } from "../../components/Editor";
import { AppImage } from "../../types/AppImage";
import React from "react";
import ImageActions from "../../actions/image-actions";
import ProjectActions from "../../actions/project-actions";


export default function ProjectCreate() {
  const [fileUploading, setFileUploading] = useState(false);
  const [file, setFile] = useState<AppImage>();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Project>({

  })

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Project> = (project) => {

    setLoading(true);

    ProjectActions.create(project).then(() => {
      setLoading(false);
      navigate("/Projects");
    });


  };

  function startUpload(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    setFileUploading(true);

    ImageActions.upload(file).then((data) => {
      getImage(data.id);
      setValue("image", data.id);

      setFileUploading(false);
    });
  }

  function getImage(id?: string): void {
    if (id) {
      ImageActions.get(id).then((data) => {
        if (data) {
          setFile(data);
        }
      })
    }

  }

  const imageUploadRef = React.useRef<HTMLInputElement>(null);

  return (
    <MainLayout>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="flex max-w-3xl flex-col gap-4 p-4">
          <div>
            <div className="mb-2 block w-full">
              <Label htmlFor="name" value="Name" />
            </div>
            <TextInput id="title" sizing="md" placeholder="A Nice Title" {...register("name", { required: true })} onChange={(e) => setValue("slug", (slugify(e.target.value)))} />
            {errors.name && <span className="text-red-500">This field is required</span>}

          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="slug" value="Slug" />
            </div>
            <TextInput id="slug" sizing="md" placeholder="a-nice-title" {...register("slug", { required: true })} />
            {errors.slug && <span className="text-red-500">This field is required</span>}
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="image" value="Image" />
            </div>
            <img className="mb-2" src={file?.url} alt="" />
            <TextInput id="image" type="text" sizing="md"  {...register("image", { required: false })} />
            <div className="mt-2">
              <div className="flex gap-4">
                <FileInput id="image" accept="image/*" sizing="md" disabled={fileUploading} onChange={
                  (e) => {
                    startUpload(e);
                  }
                } ref={imageUploadRef} />
                {
                  fileUploading && <span className="ml-2 pt-2 text-gray-500">Uploading...</span>
                }
                <Button onClick={
                  () => {
                    // reset image
                    setFile(undefined);
                    setValue("image", undefined);
                    imageUploadRef.current!.value = "";
                  }
                }>Clear</Button>
              </div>
              <span className="text-sm text-gray-500">PNG, JPG, JPEG</span>
            </div>
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="summary" value="Summary" />
            </div>
            <TextInput id="summary" type="text" sizing="md" placeholder="A nice summary" {...register("summary", { required: true })} />
            {errors.summary && <span className="text-red-500">This field is required</span>}

          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="description" value="Description" />
            </div>
            <Editor placeholder="A nice description" {...register("description", { required: true })} contentChanged={(value) => setValue("description", value)} />
            {errors.description && <span className="text-red-500">This field is required</span>}

          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="tags" value="Tags" />
            </div>
            <TextInput id="tags" type="text" sizing="md" placeholder="tag1, tag2, tag3"  {...register("tags")} />
          </div>

          <Button type="submit" disabled={loading}>Submit</Button>

        </div>
      </form>
    </MainLayout>
  );
}
