import { User } from "oidc-client-ts";

export default class UserActions {
    static getUser() {
        const oidcStorage = localStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_AUTHORITY}:${process.env.REACT_APP_AUTH_CLIENT_ID}`);
        if (!oidcStorage) {
            return null;
        }

        return User.fromStorageString(oidcStorage);
    }
}