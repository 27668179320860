import { AppImage } from "../types/AppImage";
import UserActions from "./user-actions";


const url = process.env.REACT_APP_API_URL + 'image/';

export default class ImageActions {
    static async getList(): Promise<AppImage[]> {
        const user = UserActions.getUser();
        const token = user?.access_token;
        const options = { method: 'GET', headers: { 'Authorization': 'Bearer ' + token } };

        const response = await fetch(url, options)

        if (response.status !== 200) {
            throw new Error(response.statusText)
        }
        return response.json()
    }
    static async get(id: string | undefined): Promise<AppImage | null> {
        if (!id) {
            return null
        }
        const response = await fetch(url + id)

        if (response.status !== 200) {
            throw new Error(response.statusText)
        }

        const data = await response.json()
        return data
    }


    static async upload(
        file: File
    ): Promise<AppImage> {
        const user = UserActions.getUser();
        const token = user?.access_token;
        const options = { method: 'POST', headers: { 'Authorization': 'Bearer ' + token } };
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch(url, { body: formData, ...options });

        if (response.status !== 200) {
            throw new Error(response.statusText)
        }

        const data = await response.json()
        return data
    }

    static async delete(id: string): Promise<void> {
        const user = UserActions.getUser();
        const token = user?.access_token;
        const options = { method: 'DELETE', headers: { 'Authorization': 'Bearer ' + token } };

        try {
            await fetch(url + id, options);
        } catch (error) {
            throw error;
        }
    }
}