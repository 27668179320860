
"use client";

import { Sidebar } from "flowbite-react";
import { HiChartPie, HiCode, HiInbox, HiPencil, HiPhotograph } from "react-icons/hi";
import { useSidebarStore } from "../stores/sidebar";

export function SideBar() {
    const isOpen = useSidebarStore((state) => state.isOpen);

    return (
        <>
            <Sidebar aria-label="Sidebar with multi-level dropdown"
                className={isOpen? "flex fixed top-20 left-0 z-20 flex-col flex-shrink-0 lg:pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700 w-64 ": "hidden lg:flex"}
            >
                <Sidebar.Items>
                    <Sidebar.ItemGroup>
                        <Sidebar.Item href="/" icon={HiChartPie}>
                            Dashboard
                        </Sidebar.Item>
                        <Sidebar.Collapse icon={HiPencil} label="Blog">
                            <Sidebar.Item href="/posts">Posts</Sidebar.Item>
                        </Sidebar.Collapse>
                        <Sidebar.Collapse icon={HiCode} label="Projects">
                            <Sidebar.Item href="/projects">Projects</Sidebar.Item>
                            <Sidebar.Item href="/clients">Clients</Sidebar.Item>
                        </Sidebar.Collapse>
                        <Sidebar.Collapse icon={HiPhotograph} label="Gallery">
                            <Sidebar.Item href="/albums">Albums</Sidebar.Item>
                        </Sidebar.Collapse>
                        <Sidebar.Collapse icon={HiPhotograph} label="Media">
                            <Sidebar.Item href="/images">Images</Sidebar.Item>
                        </Sidebar.Collapse>
                        <Sidebar.Item href="/inbox" icon={HiInbox}>
                            Inbox
                        </Sidebar.Item>
                    </Sidebar.ItemGroup>
                </Sidebar.Items>
            </Sidebar>
        </>
    );
}
