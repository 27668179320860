
"use client";

import { Button, Checkbox, FileInput, Label, Textarea, TextInput } from "flowbite-react";
import MainLayout from "../../layouts/MainLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Post } from "../../types/Post";
import { slugify } from "../../utils/slugify";
import { Editor } from "../../components/Editor";
import React from "react";
import PostActions from "../../actions/post-actions";
import ImageActions from "../../actions/image-actions";

export default function PostCreate() {
  const [fileUploading, setFileUploading] = useState(false);
  const [file, setFile] = useState<File>();
  const [filePreview, setFilePreview] = useState<string>();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Post>({

  })

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Post> = (post) => {

    setLoading(true);

    PostActions.create(post).then(() => {
      setLoading(false);
      navigate("/posts");
    });


  };

  function startUpload() {
    if (!file) {
      return;
    }

    setFileUploading(true);

    ImageActions.upload(file).then((data) => {
      getImage(data.id);
      setValue("image", data.id);

      setFileUploading(false);
    });
  }

  function getImage(id?: string): void {
    if (id) {
      ImageActions.get(id).then((data) => {
        if (data) {
          setFilePreview(data.url);
        }
      })
    }

  }

  const imageUploadRef = React.useRef<HTMLInputElement>(null);

  return (
    <MainLayout>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="flex max-w-3xl flex-col gap-4 p-4">
          <div>
            <div className="mb-2 block w-full">
              <Label htmlFor="title" value="Title" />
            </div>
            <TextInput id="title" sizing="md" placeholder="A Nice Title" {...register("title", { required: true })} onChange={(e) => setValue("slug", (slugify(e.target.value)))} />
            {errors.title && <span className="text-red-500">This field is required</span>}

          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="slug" value="Slug" />
            </div>
            <TextInput id="slug" sizing="md" placeholder="a-nice-title" {...register("slug", { required: true })} />
            {errors.slug && <span className="text-red-500">This field is required</span>}
          </div>
          <div className="mt-2">
            <img src={filePreview ? filePreview : file ? URL.createObjectURL(file) : ""} alt="" />

            <div className="flex gap-4">
              <FileInput id="image" accept="image/*" sizing="md" disabled={fileUploading} onChange={
                (e) => {
                  setFile(e.target.files![0]);
                }
              } ref={imageUploadRef} />
              {
                fileUploading && <span className="ml-2 pt-2 text-gray-500">Uploading...</span>
              }
              <Button disabled={fileUploading || !file} onClick={
                () => {
                  startUpload();
                }
              }>Upload</Button>

              <Button disabled={fileUploading || !file} onClick={
                () => {
                  // reset image
                  setFile(undefined);
                  setFilePreview(undefined);
                  setValue("image", undefined);
                  imageUploadRef.current!.value = "";
                }
              }>Clear</Button>
            </div>
            <span className="text-sm text-gray-500">PNG, JPG, JPEG, WEBP...</span>
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="summary" value="Summary" />
            </div>
            <Textarea id="summary" rows={4} placeholder="A nice summary" {...register("summary", { required: true })} />
            {errors.summary && <span className="text-red-500">This field is required</span>}

          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="content" value="Content" />
            </div>
            <Editor placeholder="A nice content" {...register("content", { required: true })} contentChanged={(value) => setValue("content", value)} />
            {errors.content && <span className="text-red-500">This field is required</span>}

          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="tags" value="Tags" />
            </div>
            <TextInput id="tags" type="text" sizing="md" placeholder="tag1, tag2, tag3"  {...register("tags")} />
          </div>

          <div>
            <div className="mb-2 block">
              <Label htmlFor="published" value="Published" />
            </div>
            <Checkbox
              id="published"
              checked
              {...register("isPublished")}
            />
          </div>

          <Button type="submit" disabled={loading}>Submit</Button>

        </div>
      </form>
    </MainLayout>
  );
}
