import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import { AuthProvider, withAuthenticationRequired } from 'react-oidc-context';
import { User, WebStorageStateStore } from 'oidc-client-ts';
import PostList from './pages/posts/PostList';
import PostCreate from './pages/posts/PostCreate';
import PostEdit from './pages/posts/PostEdit';
import ProjectList from './pages/projects/ProjectList';
import ProjectCreate from './pages/projects/ProjectCreate';
import ProjectEdit from './pages/projects/ProjectEdit';
import ClientList from './pages/clients/ClientList';
import ClientCreate from './pages/clients/ClientCreate';
import ClientEdit from './pages/clients/ClientEdit';
import ErrorPage from './pages/ErrorPage';
import AlbumList from './pages/gallery/AlbumList';
import AlbumCreate from './pages/gallery/AlbumCreate';
import AlbumEdit from './pages/gallery/AlbumEdit';
import MessageList from './pages/inbox/MessageList';
import ImageList from './pages/media/images/ImageList';


const oidcConfig = {
  onSignIn: () => {
    // Redirect?
  },
  authority: process.env.REACT_APP_AUTH_AUTHORITY,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirect_uri: window.location.origin,

  onSigninCallback: (_user: User | void): void => {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname
    )
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),

};


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>

      <BrowserRouter>
        <Routes>
          <Route path="/" Component={withAuthenticationRequired(App)} index />
          <Route path="/posts" Component={withAuthenticationRequired(PostList)} />
          <Route path="/posts/create" Component={withAuthenticationRequired(PostCreate)} />
          <Route path="/posts/edit/:slug" Component={withAuthenticationRequired(PostEdit)} />

          <Route path="/projects" Component={withAuthenticationRequired(ProjectList)} />
          <Route path="/projects/create" Component={withAuthenticationRequired(ProjectCreate)} />
          <Route path="/projects/edit/:slug" Component={withAuthenticationRequired(ProjectEdit)} />

          <Route path="/clients" Component={withAuthenticationRequired(ClientList)} />
          <Route path="/clients/create" Component={withAuthenticationRequired(ClientCreate)} />
          <Route path="/clients/edit/:slug" Component={withAuthenticationRequired(ClientEdit)} />

          <Route path="/albums" Component={withAuthenticationRequired(AlbumList)} />
          <Route path="/albums/create" Component={withAuthenticationRequired(AlbumCreate)} />
          <Route path="/albums/edit/:slug" Component={withAuthenticationRequired(AlbumEdit)} />

          <Route path="/images" Component={withAuthenticationRequired(ImageList)} />

          <Route path="/inbox" Component={withAuthenticationRequired(MessageList)} />

          <Route path="/error" Component={ErrorPage} />

          <Route path="*" Component={withAuthenticationRequired(App)} />
        </Routes>
      </BrowserRouter>

    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
