import { PagedResult } from "../types/PagedResult";
import { Post } from "../types/Post";
import UserActions from "./user-actions";

const url = process.env.REACT_APP_API_URL + 'post/';

export default class PostActions {
    static async getPage(): Promise<PagedResult<Post>> {
        const user = UserActions.getUser();
        const token = user?.access_token;
        const options = { method: 'GET', headers: { 'Authorization': 'Bearer ' + token } };

        try {
            const response = await fetch(url, options);
            const data = await response.json();
            return data;
        } catch (error) {
            throw error;
        }
    }

    static async getBySlug(slug: string): Promise<Post | null> {
        const user = UserActions.getUser();
        const token = user?.access_token;
        const options = { method: 'GET', headers: { 'Authorization': 'Bearer ' + token } };

        try {
            const response = await fetch(url + slug, options);
            const data = await response.json();

            return data;
        } catch (error) {
            throw error;
        }
    }

    static async getByTag(tag: string): Promise<PagedResult<Post>> {
        const user = UserActions.getUser();
        const token = user?.access_token;
        const options = { method: 'GET', headers: { 'Authorization': 'Bearer ' + token } };

        try {
            const response = await fetch(url + 'tag/' + tag, options);
            const data = await response.json();

            return data;
        } catch (error) {
            throw error;
        }
    }

    static async create(post: Post): Promise<Post> {
        const user = UserActions.getUser();
        const token = user?.access_token;
        const options = {
            method: 'POST', body: JSON.stringify(post),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json();

            return data;
        } catch (error) {
            throw error;
        }
    }

    static async update(id: string, post: Post): Promise<Post> {
        const user = UserActions.getUser();
        const token = user?.access_token;
        const options = {
            method: 'PUT', body: JSON.stringify(post),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        };

        try {
            const response = await fetch(url + id, options);
            const data = await response.json();

            return data;
        } catch (error) {
            throw error;
        }
    }

    static async delete(id: string): Promise<void> {
        const user = UserActions.getUser();
        const token = user?.access_token;
        const options = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        };

        try {
            await fetch(url + id, options);
        } catch (error) {
            throw error;
        }
    }

}