import { DashboardModel } from "../types/DashboardModel";
import UserActions from "./user-actions";

const url = process.env.REACT_APP_API_URL + 'dashboard/';

export default class DashboardActions {
    static async get(): Promise<DashboardModel> {
        const user = UserActions.getUser();
        const token = user?.access_token;
        const options = { method: 'GET', headers: { 'Authorization': 'Bearer ' + token } };

        try {
            const response = await fetch(url, options);
            const data = await response.json();
            return data;
        } catch (error) {
            throw error;
        }
    }
}