import Header from "../components/Header";
import { SideBar } from "../components/SideBar";


export default function MainLayout({ children }: { children: React.ReactNode }) {
    return (
        <>
            <Header />
            <div className="flex items-start pt-4">
                <SideBar />
                <main className="relative bg-gray-50 dark:bg-gray-900 ml-4 w-full h-full overflow-y-auto">
                    {children}
                </main>
            </div>
        </>
    )
}
