import React, { useEffect, useState } from 'react'
import { DashboardModel } from '../../types/DashboardModel';
import DashboardActions from '../../actions/dashboard-actions';

export default function Dashboard() {
    const [data, setData] = useState<DashboardModel>();
    useEffect(() => {
        DashboardActions.get().then((result) => {
            setData(result);
        });
    }, []);
    return (
        <>
            <h1>Dashboard</h1>
            <p>Post count: {data?.postCount}</p>
            <p>Client count: {data?.clientCount}</p>
            <p>Project count: {data?.projectCount}</p>
            <p>Message count: {data?.messageCount}</p>
        </>
    )
}
