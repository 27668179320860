

import { forwardRef, useRef } from 'react';
import SunEditorCore from "suneditor/src/lib/core";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { TextInputProps } from 'flowbite-react';
import SunEditor, { buttonList } from 'suneditor-react';


export const Editor = forwardRef((props: TextInputProps & { contentChanged?: (value: string) => void, value?: string }, ref) => {
    const { value, contentChanged, placeholder} = props;

    const editor = useRef<SunEditorCore>();

    // The sunEditor parameter will be set to the core suneditor instance when this function is called
     const getSunEditorInstance = (sunEditor: SunEditorCore) => {
        editor.current = sunEditor;
    };
    return (
        <>
            <SunEditor getSunEditorInstance={getSunEditorInstance}
                setOptions={{
                    height: "200",
                    buttonList: buttonList.complex, // Or Array of button list, eg. [['font', 'align'], ['image']]
                    // plugins: [font] set plugins, all plugins are set by default
                    // Other option
                    templates: [
                        {
                            name: 'Template 1',
                            html: 'Hello Template 1'
                        },
                    ],
                }}
                onChange={contentChanged}
                setContents={value?.toString()}
                placeholder={placeholder}
            />

        </>
    );
});

